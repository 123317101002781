/* eslint-disable import/no-cycle */
import EventEmitter from 'eventemitter3';
import {
    GraphConfig, GetProfileQuery,
    UpdateProfileQuery, GetMeQuery, GetDashboardQuery, GetRanksQuery,
    GetLeaderLegsQuery, GetDownlineQuery, GetRisingStarsQuery, GetMyCustomersQuery,
    GetCustomerOrdersQuery, GetSponsorQuery, GetEventsQuery, GetEventsStatisticsQuery,
    GetHostsQuery, CreateEventQuery, EditEventQuery, GetEventQuery,
    ShareEventQuery, InviteGuestQuery, GetAmbassadorsCountsQuery,
    GetImpersonateQuery,
    CancelEventQuery, InviteAmbassadorQuery, GetAssetsCategoriesQuery,
    GetNewStartersQuery, GetGenealogyEnrolleeQuery, GetLeaderLegsByIdQuery,
    GetRisingStarsByIdQuery, GetHelpAndSupportAssetsQuery, GetInviteUrlQuery,
    RegisterAmbassadorQuery, PublicInviteAmbassadorQuery, GetCustomerNoQuery,
    UploadProfilePictureQuery, GetAmbassadorKitQuery, GetTermsAndConditionsQuery,
    RespondToInvitationQuery, GetInvitationForEventQuery,
    UpdateTermsAndConditionsQuery, GetProfileMinimalQuery, GetOrdersQuery,
    RegisterAmbassadorPassportQuery, GetUnderInspectionQuery,
    GetEventInvitationQuery, GetIncomeSummaryQuery, GetHostessRegistrationQuery,
    RegisterHostessQuery, GetCustomerRegistrationQuery, RegisterCustomerQuery,
    RegisterAmbassadorDriverLicenseQuery, GetCommissionStatementsQuery,
    UpdateDownlineProfileQuery, GetSisterCreditQuery, GetRegionsQuery,
    GetHostQuery, GetReportsQuery, GetHelpAndSupportHostessAssetsQuery,
    GetEventConfirmationQuery, GetCountriesQuery, GetSuspendedQuery, GetRankingsQuery,
    UpdateBankDetailsQuery, GetCustomerOrderDetailsQuery, GetDashboardStatsQuery,
    GetNewStartersSearchOptionsQuery, GetNoPermissionBusinessDetailsQuery,
    UpdateBusinessDetailsQuery, GetPersonalQuickstartAchieversQuery,
} from './internal';

export default class Graph extends EventEmitter {
    private graphConfig: GraphConfig;

    get getGraphConfig() {
        return this.graphConfig;
    }

    public setToken(token: string | (() => string)) {
        this.graphConfig.setToken(token);
    }

    constructor() {
        super();
        this.graphConfig = new GraphConfig();
    }

    public getProfile() {
        return new GetProfileQuery(this);
    }

    public getProfileMinimal() {
        return new GetProfileMinimalQuery(this);
    }

    public updateProfile() {
        return new UpdateProfileQuery(this);
    }

    public updateDownline() {
        return new UpdateDownlineProfileQuery(this);
    }

    public uploadProfilePicture() {
        return new UploadProfilePictureQuery(this);
    }

    public getMe() {
        return new GetMeQuery(this);
    }

    public getDashboard() {
        return new GetDashboardQuery(this);
    }

    public getDashboardStats() {
        return new GetDashboardStatsQuery(this);
    }

    public getRanks() {
        return new GetRanksQuery(this);
    }

    public getLeaderLegs() {
        return new GetLeaderLegsQuery(this);
    }

    public getLeaderLegsById() {
        return new GetLeaderLegsByIdQuery(this);
    }

    public getDownline() {
        return new GetDownlineQuery(this);
    }

    public getRisingStarsById() {
        return new GetRisingStarsByIdQuery(this);
    }

    public getRisingStars() {
        return new GetRisingStarsQuery(this);
    }

    public getCustomers() {
        return new GetMyCustomersQuery(this);
    }

    public getCustomerOrders() {
        return new GetCustomerOrdersQuery(this);
    }

    public getSponsor() {
        return new GetSponsorQuery(this);
    }

    public getEvents() {
        return new GetEventsQuery(this);
    }

    public getEventsStatistics() {
        return new GetEventsStatisticsQuery(this);
    }

    public getHosts() {
        return new GetHostsQuery(this);
    }

    public getHostessRegistration() {
        return new GetHostessRegistrationQuery(this);
    }

    public getCustomerOrderDetails() {
        return new GetCustomerOrderDetailsQuery(this);
    }

    public createEvent() {
        return new CreateEventQuery(this);
    }

    public editEvent() {
        return new EditEventQuery(this);
    }

    public getEvent() {
        return new GetEventQuery(this);
    }

    public getEventConfirmation() {
        return new GetEventConfirmationQuery(this);
    }

    public cancelEvent() {
        return new CancelEventQuery(this);
    }

    public shareEvent() {
        return new ShareEventQuery(this);
    }

    public inviteGuest() {
        return new InviteGuestQuery(this);
    }

    public inviteAmbassador() {
        return new InviteAmbassadorQuery(this);
    }

    public publicInviteAmbassador() {
        return new PublicInviteAmbassadorQuery(this);
    }

    public registerAmbassador() {
        return new RegisterAmbassadorQuery(this);
    }

    public registerHostess() {
        return new RegisterHostessQuery(this);
    }

    public getAmbassadorsCounts() {
        return new GetAmbassadorsCountsQuery(this);
    }

    public getImpersonate() {
        return new GetImpersonateQuery(this);
    }

    public getAssetsCategories() {
        return new GetAssetsCategoriesQuery(this);
    }

    public getNewStarters() {
        return new GetNewStartersQuery(this);
    }

    public getNewStartersSearchOptions() {
        return new GetNewStartersSearchOptionsQuery(this);
    }

    public getGenealogyEnrollee() {
        return new GetGenealogyEnrolleeQuery(this);
    }

    public getHelpAndSupportAssets() {
        return new GetHelpAndSupportAssetsQuery(this);
    }

    public getHelpAndSupportHostessAssets() {
        return new GetHelpAndSupportHostessAssetsQuery(this);
    }

    public getInviteUrl() {
        return new GetInviteUrlQuery(this);
    }

    public getAmbassadorKit() {
        return new GetAmbassadorKitQuery(this);
    }

    public getCustomerNumber() {
        return new GetCustomerNoQuery(this);
    }

    public getTermsAndConditions() {
        return new GetTermsAndConditionsQuery(this);
    }

    public updateTermsAndConditions() {
        return new UpdateTermsAndConditionsQuery(this);
    }

    public respondToInvitation() {
        return new RespondToInvitationQuery(this);
    }

    public getInvitationForEvent() {
        return new GetInvitationForEventQuery(this);
    }

    public getOrders() {
        return new GetOrdersQuery(this);
    }

    public getEventInvitation() {
        return new GetEventInvitationQuery(this);
    }

    public getIncomeSummary() {
        return new GetIncomeSummaryQuery(this);
    }

    public getCustomerRegistration() {
        return new GetCustomerRegistrationQuery(this);
    }

    public registerCustomer() {
        return new RegisterCustomerQuery(this);
    }

    public registerDriverLicense() {
        return new RegisterAmbassadorDriverLicenseQuery(this);
    }

    public registerPassport() {
        return new RegisterAmbassadorPassportQuery(this);
    }

    public getUnderInspectionData() {
        return new GetUnderInspectionQuery(this);
    }

    public getCommissionStatements() {
        return new GetCommissionStatementsQuery(this);
    }

    public getSisterCredit() {
        return new GetSisterCreditQuery(this);
    }

    public getHost() {
        return new GetHostQuery(this);
    }

    public getReports() {
        return new GetReportsQuery(this);
    }

    public getCountries() {
        return new GetCountriesQuery(this);
    }

    public getRegions() {
        return new GetRegionsQuery(this);
    }

    public getSuspended() {
        return new GetSuspendedQuery(this);
    }

    public getRankings() {
        return new GetRankingsQuery(this);
    }

    public updateBankDetails() {
        return new UpdateBankDetailsQuery(this);
    }

    public noPermissionBusinessDetails() {
        return new GetNoPermissionBusinessDetailsQuery(this);
    }

    public updateBusinessDetails() {
        return new UpdateBusinessDetailsQuery(this);
    }

    public getPersonalQuickstartAchievers() {
        return new GetPersonalQuickstartAchieversQuery(this);
    }
}
