import { BusinessDetailsPropType, ProfileUpdateType } from '@/api/graphQL/graphNodes/types';
import { FIELD_BAD_PHONE_FORMAT, FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { validateEmail } from '@/utils/email-validation';
import resolveRegionRequirement from '@/modules/Countries/services/resolveRegionRequirement';
import { ValidationErrorType } from '../../types';
import { PROFILE_BUSINESS_DETAILS_ERROR } from '../formValidation';

const profileFormValidation:
    (val: ProfileUpdateType, businessDetails: BusinessDetailsPropType,
         isGb: boolean, region: string) =>
    ValidationErrorType[] = (val, businessDetails, isGb, region) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: FIELD_REQUIRED_MESSAGE });
            errors.push({ key: 'phoneNumberCode', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.phoneNumber) {
            const plus = val.phoneNumber.match(/\+/g);

            if (plus && plus.length > 1) {
                errors.push({ key: 'phoneNumber', val: FIELD_BAD_PHONE_FORMAT });
            }
        }

        if (val.address === null && !val.addressLookup) {
            errors.push({ key: 'addressLookup', val: FIELD_REQUIRED_MESSAGE });
        }

        const requiredBusinessDetails = resolveRegionRequirement(region);

        if (businessDetails
            && !(businessDetails.businessName && businessDetails.businessAddress)
            && ((!!businessDetails.businessAddressLookup && !businessDetails.businessName) || (
                !businessDetails.businessAddressLookup && !!businessDetails.businessName
            ))) {
            errors.push({ key: 'businessDetails.businessAddressLookup', val: PROFILE_BUSINESS_DETAILS_ERROR });
            errors.push({ key: 'businessDetails.businessName', val: PROFILE_BUSINESS_DETAILS_ERROR });
        }

        if (!isGb) {
            if (businessDetails.businessAccount) {
                if (!businessDetails.eoriNumber && requiredBusinessDetails.indexOf('eoriNumber') > -1) {
                    errors.push({ key: 'businessDetails.eoriNumber', val: FIELD_REQUIRED_MESSAGE });
                }
                if (!businessDetails.vatNumber && requiredBusinessDetails.indexOf('vatNumber') > -1) {
                    errors.push({ key: 'businessDetails.vatNumber', val: FIELD_REQUIRED_MESSAGE });
                }
            }
        }

        return errors;
    };

export default profileFormValidation;
