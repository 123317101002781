// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { CustomerNoType, TermsAndConditionsType } from './types';

export default class GetCustomerNoQuery extends AbstractQueryResource<GetCustomerNoResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!) {
            profile {
                customerNumber,
                canRegister,
                userId,
                email,
                firstName,
                lastName,
                phoneNumber,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                region,
                bankDetails {
                    bankAccountName,
                    bankAccountNumber,
                    bankSortCode,
                },
                businessDetails {
                    businessAccount,
                    businessName,
                    businessAddress,
                    eoriNumber,
                    vatNumber,
                },
                profilePicture {
                    link,
                    context {
                        metric,
                        value
                    }
                },
                lexisNexisStatus,
            },
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getCustomerNumber';
    }
}

export type GetCustomerNoResultType = {
    profile: CustomerNoType;
    termsAndConditions: TermsAndConditionsType;
}

export type GetCustomerNoParamsType = {
    type: string;
}
