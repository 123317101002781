
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Achievement from '@/projectComponents/achievement/index.vue';
import resolveStatus from '@/projectComponents/achievement/resolveStatus';
import descriptionResolver from '@/modules/NewStarters/services/descriptionResolver';
import dateManager from '@/utils/time';
import env from '@/environment';
import mockedDateManager from '@/utils/mocked-date-manager';

@Component({
    components: {
        Achievement,
    },
})
export default class NewStartersContentQuickStartMilestones extends Vue {
    @Prop() private isMobile!: boolean;

    @Prop() private milestone!: MilestoneType;

    @Prop() private labels!: {};

    get status(): string {
        return resolveStatus(this.milestone);
    }

    get description(): string {
        return descriptionResolver(this.milestone.key);
    }

    get daysLeft(): string {
        const { endDate } = this.milestone;
        if (endDate === null) {
            return '-';
        }

        let difference = dateManager
            .getDifference(
                this.mockedData ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat())
                    : dateManager.getCurrentDate({ returnFormat: dateManager.getDayTimeFormat() }),
                endDate,
                'day',
            );

        if (difference < 0) {
            difference = 0;
        }

        return `${difference} ${difference === 1 ? 'day' : 'days'} left`;
    }

    get mockedData(): boolean {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }
}
