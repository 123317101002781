export const MILESTONE_TYPE = {
    quickStart: 'quick_start',
    bdp: 'bdp',
    travel: 'travel',
    wildcard: 'wildcard',
    club250: '250_club',
};

export const MILESTONE_STATE_METRIC = {
    leaderboardPoints: 'leaderboard_points',
    entries: 'entries',
};

export const MILESTONE_CATEGORY = {
    travel: 'travel_incentive',
};

export const MILESTONE_KEY = {
    travelSelling: 'travel_selling',
    travelRecruiting: 'travel_recruiting',
    travelPromoting: 'travel_promoting',
    travelLeadership: 'travel_leadership',
    consistentLeaderWildcard: 'consistent_leader_wildcard',
    newLeaderWildcard: 'new_leader_wildcard',
    activityWildcard: 'activity_wildcard',
    fullIncentiveCriteria: 'full_incentive_criteria',
    fakeTravel: 'fake_travel_milestone',
    club250: '250_club',
};

export const AWARD_KEY = {
    topTravelSeller: 'top_travel_seller',
    topTravelRecruiter: 'top_travel_recruiter',
    topTravelLeader: 'top_travel_leader',
    topTravelOverall: 'top_travel_overall',
};

export const MILESTONES_TOOLTIP_TYPES = {
    travel: 'travel',
    wildcard: 'wildcard',
    business: 'business',
    quick: 'quick',
};

export const BUSINESS_DEVELOPMENT_TYPES = {
    silver: 'silver',
    bronze: 'bronze',
    gold: 'gold',
};

export const QUICK_START_MILESTONE_TYPES = {
    kitBoost: 'kit_boost',
    kitRefund: 'kit_refund',
    businessBoost: 'business_boost',
    doublePromotion: 'double_promotion',
    salesStarter: 'sales_starter',
    salesBuilder: 'sales_builder',
    salesChampion: 'sales_champion',
    teamStarter: 'team_starter',
    teamBuilder: 'team_builder',
    teamChampion: 'team_champion',
};
