
import { Vue, Component } from 'vue-property-decorator';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { Get } from '@/utils/vuex-module-mutators';
import { StatsType } from '@/api/graphQL/graphNodes/types';
import { PaginationParamsType } from '@/components/pagination/types';
import quickStartAchievers from '@/modules/Dashboard/quickStartAchievers';
import wildcardManager from '@/modules/Dashboard/services/wildcardManager';

@Component({
    components: {
        Skeleton,
    },
})
export default class QuickStartAchievers extends Vue {
    @Get(quickStartAchievers) private loading!: boolean;

    @Get(quickStartAchievers) private loadingInBackground!: boolean;

    @Get(quickStartAchievers) private limit!: number;

    @Get(quickStartAchievers) private offset!: number;

    @Get(quickStartAchievers) private total!: number;

    @Get(quickStartAchievers) private data!: StatsType[];

    get title(): string {
        const { type } = this;
        const labels = wildcardManager.quickStartLabelResolver(type);
        return labels.full;
    }

    get type(): string {
        return this.$route.params.type;
    }

    handlePagination(props: PaginationParamsType) {
        const { type } = this;
        quickStartAchievers.setOffset(props.offset);
        quickStartAchievers.getData({ type, loadInBackground: true });
    }

    async mounted() {
        quickStartAchievers.getData({ type: this.type, loadInBackground: false });
    }

    beforeDestroy() {
        quickStartAchievers.resetData();
    }
}
