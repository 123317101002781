// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { BusinessDetailsPropType } from './types';

export default class GetNoPermissionBusinessDetailsQuery extends
    AbstractQueryResource<GetNoPermissionBusinessDetailsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                businessDetails {
                    businessAccount,
                    businessName,
                    businessAddress,
                    eoriNumber,
                    vatNumber,
                },
                region,
            },
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getNoPermissionBusinessDetails';
    }
}

export type GetNoPermissionBusinessDetailsResultType = {
    profile: {
        businessDetails: BusinessDetailsPropType|null,
        region: string;
    };
}
