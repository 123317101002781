const stats = `type Stats {
    metric: String!
    value: String!,
    status: Boolean,
    label: String,
}`;

export {
    stats,
};
