// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { StatsType } from './types';

export default class GetPersonalQuickstartAchieversQuery extends
    AbstractQueryResource<GetPersonalQuickstartAchieversResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($milestoneCategory: String!, $limit: Int!, $offset: Int!) { 
            profile {
                personalQuickstartAchievers(milestoneCategory: $milestoneCategory, offset: $offset, limit: $limit) {
                    metric,
                    value
                },
                personalQuickstartAchieversCount(milestoneCategory: $milestoneCategory),
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getPersonalQuickstartAchievers';
    }
}

export type GetPersonalQuickstartAchieversResultType = {
    profile: {
        personalQuickstartAchievers: StatsType[];
        personalQuickstartAchieversCount: number;
    };
}

export type GetPersonalQuickstartAchieversParamsType = {
    milestoneCategory: string;
    limit: number;
    offset: number;
}
