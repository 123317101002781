import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { StatsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import dashboardRepository from './services/dashboardRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'dashboardQuickStartAchievers',
})
@AutoMutations
export class QuickStartAchievers extends VuexModule {
    private data: StatsType[] = [];

    private total: number = 0;

    private limit: number = 10;

    private offset: number = 0;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    public resetOffset() {
        this.offset = 0;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    public setData(val: StatsType[] = []) {
        this.data = [...val];
    }

    @Action()
    public async resetData() {
        this.resetOffset();
        this.setData();
        this.setTotal(0);
    }

    @Action()
    public async getData(params: { type: string, loadInBackground: boolean }) {
        const { loadInBackground } = params;
        try {
            if (loadInBackground) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const { limit, offset } = this;
            const { type } = params;

            const res = await dashboardRepository.getPersonalQuickstartAchievers({
                limit,
                offset,
                milestoneCategory: type,
            });

            this.setTotal(res.profile.personalQuickstartAchieversCount);
            this.setData(res.profile.personalQuickstartAchievers);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (loadInBackground) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }
}

export default getModule(QuickStartAchievers);
