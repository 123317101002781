import {
    AchievementAwardType, DashboardType, MilestoneType,
} from '@/api/graphQL/graphNodes/types';

const initialDashboardData: () => DashboardType = () => ({
    id: '0',
    rankId: '0',
    rank: {
        id: '0',
        power: 0,
        label: '',
        requirements: [],
        titleRequirements: [],
    },
    additionalCommission: '0.00',
    ctb: '0.00',
    leadershipBonus: '0.00',
    centralTeamBonus: '0.00',
    performanceData: {
        avgSalesPerEvent: '0.00',
        bookedEvents: 0,
        finishedEvents: 0,
        projectedSales: '0.00',
        totalSales: 0,
        lastMonthFinishedEvents: 0,
    },
    newAmbassadorsInCentralTeam: 0,
    centralTeamCount: 0,
    newAmbassadorsOrganizational: 0,
    newAmbassadorsPersonal: 0,
    organizationCount: 0,
    activeOnFileDirectEnrollees: 0,
    thisSeason: [],
    nextSeason: [],
    runTotalsForPeriod: [],
    participatedRuns: [],
    personalQuickstartsAchieversMonthlyCounts: [],
});

const initialRankings: () => AchievementAwardType = () => ({
    description: '',
    tooltip: '',
    leaderboard: [],
    title: '',
    type: '',
});

const defaultMilestone: () => MilestoneType = () => ({
    achieved: false,
    achievedAt: null,
    category: '',
    endDate: '',
    key: '',
    startDate: '',
    state: [],
    type: '',
    unachievable: false,
});

export {
    initialDashboardData, initialRankings, defaultMilestone,
};
