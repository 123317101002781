import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import {
    CustomAction as Action, ErrorType, ObjectProcessor,
} from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import componentsControl from '@/modules/ComponentsControls';
import {
    AssetType, BankDetailsType, BusinessDetailsPropType, BusinessDetailsType, ProfileType,
    ProfileUpdateType, UploadProfilePictureType,
} from '@/api/graphQL/graphNodes/types';
import { UpdateProfileResultType } from '@/api/graphQL/graphNodes/UpdateProfileQuery';
import { GetProfileResultType, ProfileParamsType } from '@/api/graphQL/graphNodes/GetProfileQuery';
import Vue from 'vue';
import {
    FIELD_REQUIRED_MESSAGE, INVALID_PHONE_NUMBER,
    UPDATED_SUCCESSFULLY,
} from '@/utils/messages/formValidation';
import { FileType } from '@/components/fileArea/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import addressSelect from '@/modules/AddressSelect';
import profileFormValidation from './services/profileFormValidation';
import { LabelType, ObjectPropertyType } from '../types';
import {
    profile as defaultProfile, defaultBankDetails, defaultProfilePictureData,
    defaultBusinessDetails,
} from './defaults';
import { gbLabels, resolveBankingDetails } from '../labels';
import { COUNTRY_SELECT_OPTIONS, FORM_ERROR_TOOLTIP } from '../constants';
import ProfileRepository from './services/profileRepository';
import { TERMS_AND_CONDITIONS_TYPE } from './constants';
import PhoneNumberManager from '../Register/services/phoneNumberManager';
import { defaultPropBusinessDetails } from '../defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'profile',
})
@AutoMutations
export class Profile extends VuexModule {
    private originalData: ProfileType = defaultProfile();

    private data: ProfileType = defaultProfile();

    private bankDetails: BankDetailsType = defaultBankDetails();

    private businessDetails: BusinessDetailsType = defaultBusinessDetails();

    private disableBusinessDetails: boolean = false;

    private profilePicture: UploadProfilePictureType = defaultProfilePictureData();

    private shopUrl: string = '';

    private shopLoginUrl: string = '';

    private labels: LabelType = { ...gbLabels() };

    private loading: boolean = false;

    private imageDialogOpen: boolean = false;

    private profilePicUpload: FileType|null = null;

    private cameraDialogOpen: boolean = false;

    private formErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private vanityUrl: string = '';

    private termsDialogOpen: boolean = false;

    private termsAndConditionsFile: AssetType|null = null;

    get getProfileData(): ProfileType {
        return this.data;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setProfileData(data: Partial<ProfileType>) {
        this.data = ObjectProcessor.objectMerge(defaultProfile(), ObjectProcessor
            .objectMerge(this.data, data));
        this.originalData = ObjectProcessor.objectMerge(defaultProfile(), ObjectProcessor
            .objectMerge(this.originalData, data));
    }

    @Mutation
    public setBankDetails(data: BankDetailsType) {
        this.bankDetails = { ...defaultBankDetails(), ...data };
    }

    @Mutation
    public setBusinessDetails(data: BusinessDetailsType) {
        this.businessDetails = { ...defaultBusinessDetails(), ...data };
    }

    @Mutation
    public setDisableBusinessDetails(val: boolean) {
        this.disableBusinessDetails = val;
    }

    @Mutation
    public setFormData(data: Partial<ProfileType>) {
        this.data = { ...this.data, ...data };
    }

    @Mutation
    public setProfilePicture(val: UploadProfilePictureType) {
        this.profilePicture = { ...val };
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public resetDataToOriginal() {
        this.data = { ...this.originalData };
        this.bankDetails = { ...defaultBankDetails() };
        this.businessDetails = { ...defaultBusinessDetails() };
    }

    @Mutation
    public setShopUrl(val: string) {
        this.shopUrl = val;
    }

    @Mutation
    public setShopLoginUrl(val: string) {
        this.shopLoginUrl = val;
    }

    @Mutation
    public setImageDialogOpen(val: boolean) {
        this.imageDialogOpen = val;
    }

    @Mutation
    public setCameraDialogOpen(val: boolean) {
        this.cameraDialogOpen = val;
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors = {
            ...this.formErrors,
            [payload.key]: payload.val,
        };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setVanityUrl(val: string) {
        this.vanityUrl = val;
    }

    @Mutation
    toggleTermsDialogOpen() {
        this.termsDialogOpen = !this.termsDialogOpen;
    }

    @Mutation
    public setTermsAndConditionsFile(val: AssetType|null) {
        this.termsAndConditionsFile = val;
    }

    @Mutation
    public setBusinessAccount(val: boolean) {
        this.businessDetails.businessAccount = val;
    }

    @Mutation
    public clearBusinessBusinessDetails() {
        this.businessDetails = {
            ...this.businessDetails,
            businessAddressLookup: '',
            businessAddress: '',
            eoriNumber: '',
            vatNumber: '',
            businessName: '',
        };
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public validateInvitationForm(payload: {
        data: ProfileUpdateType;
        businessDetails: BusinessDetailsPropType;
        isGb: boolean;
        region: string;
    }) {
        this.clearFormErrors();
        const {
            data, businessDetails, isGb, region,
        } = payload;
        const formErrors = profileFormValidation(data, businessDetails, isGb, region);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public async getProfile(params: {
        isImpersonating: boolean, isAmbassador: boolean, phoneValidation?: boolean
    }) {
        try {
            this.setLoading(true);

            const { isImpersonating, isAmbassador, phoneValidation = false } = params;
            const reqParams: ProfileParamsType = {
                type: isAmbassador
                    ? TERMS_AND_CONDITIONS_TYPE.ambassador
                    : TERMS_AND_CONDITIONS_TYPE.hostess,
            };
            const result: GetProfileResultType = await ProfileRepository
                .getProfile(reqParams) as GetProfileResultType;
            const { rank, businessDetails } = result.profile;

            // @ts-ignore
            Vue.$gtag.set({
                Rank: { label: rank.label, id: rank.id },
            });

            this.setVanityUrl(result.details.vanityUrl);

            if (result.profile.profilePicture !== null) {
                this.setProfilePicture(result.profile.profilePicture);
            } else {
                this.setProfilePicture(defaultProfilePictureData());
            }

            // business details setting
            if (
                businessDetails
                && businessDetails.businessAddress
                && businessDetails.businessName
            ) {
                this.setDisableBusinessDetails(true);
            } else {
                this.setDisableBusinessDetails(false);
            }

            const formattedBusinessDetails = !businessDetails
                ? defaultBusinessDetails() : {
                    ...defaultBusinessDetails(),
                    ...ObjectProcessor.removeEmptyProperties(businessDetails),
                };

            this.setBusinessDetails(formattedBusinessDetails);

            // terms and conditions
            if (result.termsAndConditions) {
                this.setTermsAndConditionsFile(result.termsAndConditions.content);
            }

            const phoneNumberObject = PhoneNumberManager
                .getPhoneNumberObject(result.profile.phoneNumber);

            const formattedData = {
                ...ObjectProcessor.removeEmptyProperties(result.profile),
                phoneNumber: phoneNumberObject.number,
                phoneNumberCode: phoneNumberObject.code,
            };

            if (phoneValidation && formattedData.phoneNumberCode === '' && formattedData.phoneNumber !== '') {
                componentsControl.showErrorMessage({ message: 'Please update your phone number' });
                this.setFormError({ key: 'phoneNumber', val: INVALID_PHONE_NUMBER });
                this.setFormError({ key: 'phoneNumberCode', val: FIELD_REQUIRED_MESSAGE });
            }

            if (!isImpersonating) {
                const { bankSortCode } = result.profile.bankDetails;

                const formattedBankSortCode = bankSortCode === null ? '' : bankSortCode.replace(/-/g, '');
                this.setBankDetails(ObjectProcessor.removeEmptyProperties({
                    ...formattedData.bankDetails, bankSortCode: formattedBankSortCode,
                }));
            }

            this.setProfileData(formattedData);

            this.setLabels(resolveBankingDetails(result.profile.region).labels);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async updateProfile(isHostess: boolean = false) {
        try {
            const isGb = this.data.region === COUNTRY_SELECT_OPTIONS.gb;

            if (!isGb) {
                if (!this.businessDetails.businessAccount) {
                    this.clearBusinessBusinessDetails();
                    profileBusinessAddressSelect.resetData();
                }
            }

            const { addressId } = profileBusinessAddressSelect;
            const businessDetails: BusinessDetailsPropType = ObjectProcessor
                .objectMerge(
                    defaultPropBusinessDetails(),
                    ObjectProcessor
                        .removeEmptyProperties({
                            ...this.businessDetails,
                            businessAddressLookup: addressId === '' ? null : addressId,
                        }),
                );

            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                id, parentId, profilePicture, rank,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                paidForKit, joinDate, kitPurchaseDate, phoneNumber, phoneNumberCode,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                secondAddress, postCode, country, region, county, town, ...oldData
            } = this.data;

            const data: ProfileUpdateType = {
                ...oldData,
                phoneNumber: phoneNumberCode ? `(${phoneNumberCode})${phoneNumber}` : phoneNumber,
                addressLookup: addressSelect.addressId,
            };

            await this.validateInvitationForm({
                data, businessDetails, isGb, region,
            });

            if (!phoneNumberCode) {
                this.setFormError({ key: 'phoneNumberCode', val: FIELD_REQUIRED_MESSAGE });
            }

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoading(true);
                this.setDisableBusinessDetails(false);

                const reqData = {
                    ...data,
                    bankDetails: isHostess ? null : this.bankDetails,
                    businessDetails,
                };

                const result: UpdateProfileResultType = await ProfileRepository
                    .updateProfile(reqData);

                const phoneNumberObject = PhoneNumberManager
                    .getPhoneNumberObject(result.updateProfile.phoneNumber);

                const formattedData = {
                    ...ObjectProcessor.removeEmptyProperties(result.updateProfile),
                    phoneNumber: phoneNumberObject.number,
                    phoneNumberCode: phoneNumberObject.code,
                };

                // handling business details
                const businessDetailsRes = formattedData.businessDetails
                 || defaultBusinessDetails();

                if (businessDetailsRes && businessDetailsRes.businessAddress
                    && businessDetailsRes.businessName) {
                    this.setDisableBusinessDetails(true);
                } else {
                    this.setDisableBusinessDetails(false);
                }

                const formattedBusinessDetails = {
                    ...defaultBusinessDetails(),
                    ...ObjectProcessor.removeEmptyProperties(businessDetails),
                };

                this.setBusinessDetails(formattedBusinessDetails);

                this.setProfileData(formattedData);
                componentsControl.showSuccessMessage({ message: UPDATED_SUCCESSFULLY });
                addressSelect.setAddress();
                addressSelect.clearOptions();
                profileBusinessAddressSelect.setAddress();
                profileBusinessAddressSelect.clearOptions();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            if (this.businessDetails.businessAddress
                && this.businessDetails.businessName) {
                this.setDisableBusinessDetails(true);
            } else {
                this.setDisableBusinessDetails(false);
            }

            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Profile);
