
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import newStarters, { NewStarters } from '@/modules/NewStarters';
import newStartersSearchOptions from '@/modules/NewStarters/searchOptions';
import { QuickstartAchieversType } from '@/api/graphQL/graphNodes/types';

@Component
export default class RecruitmentPerformance extends Vue {
    @Get(dashboard, 'data.newAmbassadorsInCentralTeam') private newAmbassadorsInCentralTeam!: number;

    @Get(dashboard, 'data.centralTeamCount') private centralTeamCount!: number;

    @Get(dashboard, 'data.newAmbassadorsOrganizational') private newAmbassadorsOrganizational!: number;

    @Get(dashboard, 'data.newAmbassadorsPersonal') private newAmbassadorsPersonal!: number;

    @Get(dashboard, 'data.organizationCount') private organizationCount!: number;

    @Get(dashboard, 'data.personalQuickstartsAchieversMonthlyCounts') private personalQuickstartsAchieversMonthlyCounts!: QuickstartAchieversType[]

    get personalType() {
        return NewStarters.NEW_STARTERS_TYPE.personal;
    }

    get centralType() {
        return NewStarters.NEW_STARTERS_TYPE.central;
    }

    get organisationalType() {
        return NewStarters.NEW_STARTERS_TYPE.organisational;
    }

    handleOpenNewStarters(type) {
        newStartersSearchOptions.setQuery('');
        newStarters.setSearchedLegs([]);
        newStartersSearchOptions.setOptions([]);
        newStarters.setType(type);
        newStarters.setNewStartersTimeFrame(newStarters.typeThisMonth);
        this.$router.push({ name: 'newPartners' });
    }

    handleOpenQuickStartAchievements(metric) {
        this.$router.push({ name: 'quickStartAchievers', params: { type: metric } });
    }
}
